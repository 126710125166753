import React, { useEffect, useState } from 'react';
import { database } from '../../Firebase/firebase';
import { ref, onValue, off } from 'firebase/database';
import TabWithDropdown from './TabWithDropdown'; 
import SliderCustom from '../../Components/Slider/SliderCustom';
import TabArreglos from "../../Components/Tabs/arreglosTab";
const Inicio = () => {
    const [data, setData] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 630);
    const [isSmallScreen2, setIsSmallScreen2] = useState(window.innerWidth > 950);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth > 630);
            setIsSmallScreen2(window.innerWidth > 950);
        };

       // Agrega un event listener para manejar cambios de tamaño de pantalla
       window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);

        };
    }, []);

    return (
        <div className="pb-8 pl-4 pr-4 w-full flex flex-col justify-center items-center bg-fondo_pastel_morado">
            <div className=" w-full rounded-lg flex flex-col justify-center items-center  bg-white">
                {/* {isSmallScreen && <TabWithDropdown />} */}
                <SliderCustom />
                <TabArreglos></TabArreglos>
            </div>
        </div>
    );

}

export default Inicio

