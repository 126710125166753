import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyB8liqjLLoTbAccNxwZGXpA9kFgLKNI1rc",
    authDomain: "arreglos-florales-47abb.firebaseapp.com",
    databaseURL: "https://arreglos-florales-47abb-default-rtdb.firebaseio.com",
    projectId: "arreglos-florales-47abb",
    storageBucket: "arreglos-florales-47abb.appspot.com",
    messagingSenderId: "702016328037",
    appId: "1:702016328037:web:d0df8422570261beff118c",
    measurementId: "G-P8SK6FC140"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
