const textData = [
    {
        title: "Entregas a Domicilio",
        description1: "Depende del lugar, varía el costo de 2 a 6 dólares.",
        description2: "⚠️ Realiza con tiempo tu pedido.",
        buttonText: "Preguntar 1",
    },
    {
        title: "Arreglos florales",
        description1: "Arreglos florales 🌼, arreglos frutales🍓",
        description2: "🌵 Mini jardines (Plantas naturales) chocofrutas",
        buttonText: "Preguntar 2",
    },
    {
        title: "Entregas",
        description1: "📍Quito sur, centro y norte",
        description2: "Valle de los chillos, sangolquí, conocoto",
        buttonText: "Preguntar 3",
    }
];

export default textData;
