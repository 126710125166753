import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Pagination } from '@mui/material';
import Modal from '../../Components/Modales/modalimagen';
import Modalpedido from '../../Components/Modales/modalpedido';
import fetcher from '../../Firebase/fetcher'; // Asegúrate de importar tu función fetcher para obtener datos

const Arreglos = ({ tabName, searchTerm }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [modalpedidoIsOpen, setModalpedidoIsOpen] = useState(false); // Estado para controlar el modal de pedidos

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetcher(`Categorias/${tabName}`); // Usar tabName para filtrar datos
                setData(response);
                setError(null);
            } catch (error) {
                setError(error);
                setData(null);
            }
        };

        fetchData();
    }, [tabName]); // Actualizar cuando cambie tabName

    const openModal = (image, name, price) => {
        setSelectedImage(image);
        setSelectedName(name);
        setSelectedPrice(price);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
        setSelectedName(null);
        setSelectedPrice(null);
    };

    const openModalpedido = () => {
        setModalpedidoIsOpen(true);
        setModalIsOpen(false); // Cierra el modal de imágenes al abrir el modal de pedidos
    };

    const closeModalpedido = () => {
        setModalpedidoIsOpen(false);
    };

    // Filtrar y ordenar datos basados en searchTerm si está definido
    const filteredData = data ? Object.values(data)
        .filter(item => searchTerm ? item.nombre.toLowerCase().includes(searchTerm.toLowerCase()) : true)
        .sort((a, b) => a.num - b.num) : [];

    // Calcula los índices de los elementos actuales según la paginación y los datos filtrados
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    return (
        <div className=' rounded-lg'>
            {/* Contenido específico de la pestaña */}
            <div className='w-full flex flex-wrap justify-center'>
                {currentItems.map((cliente, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center lg:m-2 xspeque:mb-2 justify-center shadow-md"
                    >
                        {/* Imagen del cliente */}
                        <Button
                            onClick={() => openModal(cliente.imagen, cliente.nombre, cliente.precio)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={cliente.imagen}
                                alt={cliente.nombre}
                                className="lg:w-60 lg:h-60 xspeque:w-25px xspeque:h-25px cursor-pointer"
                            />
                        </Button>
                        <div>
                            <p className='text-black'>{cliente.nombre}</p>
                            <p className="text-gray-600">Precio: {cliente.precio} $</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal de imágenes */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                image={selectedImage}
                name={selectedName}
                price={selectedPrice}
                openModalpedido={openModalpedido} // Pasamos la función para abrir el modal de pedidos
            />

            {/* Modal de pedidos */}
            <Modalpedido
                isOpen={modalpedidoIsOpen}
                onRequestClose={closeModalpedido}
                image={selectedImage}
                name={selectedName}
                price={selectedPrice}
            />

            {/* Paginación personalizada */}
            <div className="flex justify-center mt-2 mb-2">
                <button className='text-white' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}><ArrowBackIosIcon /></button>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    boundaryCount={1}
                    siblingCount={1}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    size="small"
                />
                <button className='text-white' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}><ArrowForwardIosIcon /></button>
            </div>
        </div>
    );
}

export default Arreglos;
