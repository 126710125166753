import { database } from './firebase';
import { ref, get } from 'firebase/database';

const fetcher = async (path) => {
  const dbRef = ref(database, path);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    throw new Error('No data available');
  }
};
export default fetcher;
