import React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Inicio from "../Pages/Inicio/Inicio";
import Memorias from "../Pages/Memorias/memorias";
import Administrar from "../Pages/Administrar";
import Contactos from "../Pages/Contactos/contactos";
import PreciosEnvio from "../Pages/PreciosDeEnvio/preciosenvio";

function NavPage() {
    return (
        <React.Fragment>
            <section >
                <Routes>
                    <Route path="/" element={<Inicio />} />
                    <Route path="/Administrar" element={<Administrar />} />
                    <Route path="/contactos" element={<Contactos />} />
                    <Route path="/preciosenvio" element={<PreciosEnvio />} />
                    <Route path="/memorias/:personaId" element={<Memorias />} />
                </Routes>
            </section>
        </React.Fragment>
    );
};

export default NavPage;
