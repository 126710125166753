import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Arreglos from "../../Pages/Arreglos/arreglos";
import searchTerms from "../Data/categorias.json"; // Asegúrate de que la ruta es correcta

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="w-full"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: 0, margin: 0 }}>
                    <Typography
                    >{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function CatalogueTab() {
    const [value, setValue] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchTerm("");
    };

    const handleSearchChange = (event, newValue) => {
        setSearchTerm(newValue ? newValue.label : "");
    };

    return (
        <Box className="sticky top-0 bg-white z-10" sx={{ width: "100%", position: "sticky" }}>
            <div className="sticky top-0 bg-white z-10">
                <Tabs
                    value={value}
                    centered
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        // border: "2px solid red",
                        position: "sticky",
                        flexDirection: { xs: 'column', sm: 'row' },
                        // border: "2px solid red",
                        padding: "0px",
                        margin: "0px",
                        height: "20px"
                    }}
                >
                    <Tab

                        label="Todos"
                        {...a11yProps(0)}
                    // Ajusta el padding aquí
                    />
                    <Tab
                        label="Mini Jardines"
                        {...a11yProps(1)}

                    />
                    <Tab
                        label="Chocofrutas"
                        {...a11yProps(2)}
                    />
                </Tabs>
                <Box sx={{ paddingTop: 1, paddingX: 2, width: { xs: '100%', sm: '250px' }, margin: "0 auto" }}>
                    <Autocomplete
                        options={searchTerms}
                        getOptionLabel={(option) => option.label}
                        value={searchTerms.find(term => term.label === searchTerm) || null}
                        onChange={handleSearchChange}
                        // ListboxComponent={CustomListbox}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Buscar categoría"
                                variant="outlined"
                                sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root': {
                                        padding: { xs: '5px', lg: '10px' }, // Ajusta el padding interno según el tamaño de la pantalla
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: { xs: '0.75rem', lg: '1rem' }, // Ajusta el tamaño de la etiqueta según el tamaño de la pantalla
                                    },
                                    '& .MuiInputBase-input': {
                                        fontSize: { xs: '0.75rem', lg: '1rem' }, // Ajusta el tamaño del texto de entrada según el tamaño de la pantalla
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </div>

            <TabPanel value={value} index={0}>
                <Arreglos tabName={searchTerm ? searchTerm : "Todos"} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Arreglos tabName={searchTerm ? searchTerm : "Mini jardines"} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Arreglos tabName={searchTerm ? searchTerm : "Chocofrutas"} />
            </TabPanel>
        </Box>
    );
}
