import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Modalpedido from '../../Components/Modales/modalpedido';
import { WhatsApp as WhatsAppIcon, ShoppingBasket as ShoppingCartIcon } from '@mui/icons-material';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    content: {
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '600px',
        width: '90%',
        height: '90%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1200,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        color: '#000',
    },
};

const Modal = ({ isOpen, onRequestClose, image, name, price, openModalpedido }) => {

    const phoneNumber = '593990677029'; // Reemplaza con el número de teléfono de WhatsApp
    const whatsappMessage = `Deseo más información sobre ${name} Precio: ${price} `;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    if (window.innerWidth < 599) {
        customStyles.content.height = '75%';
    }
    return (



        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Detalles de la imagen"
            ariaHideApp={false}
            style={customStyles}
        >
            <Box className="flex justify-end w-full">
                <IconButton onClick={onRequestClose} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            <div className='h-full  flex justify-center items-center'>
                {image && (

                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Zoom>
                            <img src={image} alt={name} style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
                        </Zoom>
                        <div className='w-full flex flex-row justify-between'>
                            <Typography variant="h6" component="p" m={2} align="center">
                                {name}
                            </Typography>
                            <Typography variant="h6" component="p" m={2} align="center">
                                Precio: {price}$
                            </Typography>
                        </div>

                        <Box className="w-full flex flex-row justify-between items-center">
                            <Button
                                variant="contained"
                                href={whatsappLink} // Solo habilitar el enlace si la URL está acortada
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    backgroundColor: '#25d366',
                                    color: '#fff',
                                    marginRight: '10px'
                                }}
                                startIcon={<WhatsAppIcon />}
                            >
                                Preguntar
                            </Button>
                            <Button
                                onClick={openModalpedido} // Aquí cambia la acción al abrir el segundo modal
                                variant="contained"
                                color="primary"
                                href="#"
                                startIcon={<ShoppingCartIcon />}
                            >
                                Pedido
                            </Button>
                        </Box>
                    </Box>
                )}
            </div>

        </ReactModal>
    );
};

export default Modal;
