import React from 'react';
import { Box, Typography, IconButton, Container, Grid, Paper } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from '../../Components/Images/logo2.png'; // Asegúrate de que la ruta es correcta

const Contactos = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f3e5f5',
        padding: '20px ',
        borderRadius: "15px",
        height:'100%',
        margin: '0px 40px 40px 40px'
      }}
    >
      <div className='lg:w-96 xspeque:w-60'
       >
        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '50px',
            }}
          >
            <img src={logo} alt="Bello Arte" style={{ width: '150px' }} />
          </Box>
          <Typography variant="h4" component="h1" gutterBottom>
            Contactos
          </Typography>
         
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: '20px' }}>
            <IconButton
              color="primary"
              aria-label="Facebook"
              component="a"
              href="https://www.facebook.com/BellloArte/reviews"
              target="_blank"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="Instagram"
              component="a"
              href="https://www.instagram.com/bello_arte_flores/"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="error"
              aria-label="Email"
              component="a"
              href="mailto:belloartefv@gmail.com"
            >
              <EmailIcon />
            </IconButton>
            <IconButton
              color="success"
              aria-label="WhatsApp"
              component="a"
              href="https://wa.me/0990677029"
              target="_blank"
            >
              <WhatsAppIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" paragraph>
            <strong>Email:</strong> belloartefv@gmail.com
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>WhatsApp:</strong> 0990677029
          </Typography>
         
        </Paper>
      </div>
    </Box>
  );
};

export default Contactos;
