// Footer.js
import React from 'react';
import { Box, Container, Grid, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import logo from '../Components/Images/logo2.png'; // Asegúrate de que la ruta es correcta
import VisaIcon from '../Components/Images/pago/visa.png';
import MasterCardIcon from '../Components/Images/pago/mastercard.png';
import BitcoinIcon from '../Components/Images/pago/acpbtc.jpeg';
import PayPalIcon from '../Components/Images/pago/paypal.png';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#333', color: 'white', padding: '20px 0', marginTop: 'auto' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img src={logo} alt="Bello Arte" style={{ width: '150px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div" gutterBottom>
              Acerca de Nosotros
            </Typography>
            <Typography variant="body2">
              En Bello Arte, creamos arreglos florales y frutales para toda ocasión. Transformamos tus ideas en detalles únicos que deleitan y sorprenden.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div" gutterBottom>
              Métodos de Pago
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <img src={VisaIcon} alt="Visa" style={{ width: '40px' }} />
              <img src={MasterCardIcon} alt="MasterCard" style={{ width: '40px' }} />
              <img src={BitcoinIcon} alt="Bitcoin" style={{ width: '40px' }} />
              <img src={PayPalIcon} alt="PayPal" style={{ width: '40px' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginTop: '10px' }}>
              <IconButton
                color="inherit"
                aria-label="Facebook"
                component="a"
                href="https://www.facebook.com/BellloArte/reviews"
                target="_blank"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="Instagram"
                component="a"
                href="https://www.instagram.com/bello_arte_flores/"
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="Email"
                component="a"
                href="mailto:belloartefv@gmail.com"
              >
                <EmailIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="WhatsApp"
                component="a"
                href="https://wa.me/0990677029"
                target="_blank"
              >
                <WhatsAppIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="div" gutterBottom>
              Contáctanos
            </Typography>
            <Typography variant="body2">Email: belloartefv@gmail.com</Typography>
            <Typography variant="body2">Tel: 0990677029</Typography>
          </Grid>
        </Grid>
        <Box sx={{ borderTop: '1px solid #444', paddingTop: '10px', marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="body2">&copy; 2023 BelloArte.</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
