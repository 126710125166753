import React from 'react';
import { Dialog, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PopupMessage = ({ isOpen, onClose }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: '10px',
                    width: '300px', // Ajusta el ancho según lo necesites
                    maxWidth: '300px', // Para asegurarse de que no exceda el ancho especificado
                    margin: 'auto', // Centra el diálogo
                },
            }}
        >
            <DialogContent
                sx={{ padding: '20px', textAlign: 'center' }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        color: '#757575',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                >
                    ⚠️ Información Importante
                </Typography>
                <Typography variant="body1">
                    Es necesario saber quién envía el pedido porque en ocasiones no nos
                    permiten entregar el arreglo si no conocen al remitente.
                </Typography>
            </DialogContent>
        </Dialog>

    );
};

export default PopupMessage;
