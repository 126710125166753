import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import icono from '../Components/Images/logo2.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import CloseIcon from '@mui/icons-material/Close';
function Navbar({ onMenuClick, windowWidth }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    onMenuClick(!menuOpen); // Pasa el estado del menú a la función del padre
  };
  return (
    <AppBar className='bg_navbar' position="fixed" elevation={0} style={{ zIndex: 1000, background: '#b8bedd' }}>
      <Toolbar className="justify-between ">
        {windowWidth &&
          <IconButton edge="start" color="black" aria-label="menu" onClick={toggleMenu}>
            {/* {menuOpen ? <CloseIcon /> : <MenuIcon />} */}
          </IconButton>}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={icono} alt="Icono" style={{ width: '25px', height: '25px', marginRight: "6px" }} />
          <h1 className="text-black" style={{ fontSize: '1rem', marginRight: '8px' }}>Bello Arte</h1>
        </div>
        <div>
          <IconButton
            edge="start"
            color="black"
            aria-label="facebook"
            component="a"
            href="https://www.facebook.com/BellloArte"
            target="_blank"
          >
            <FacebookIcon style={{ fontSize: 25 }} />
          </IconButton>
          <IconButton
            edge="start"
            color="black"
            aria-label="instagram"
            component="a"
            href="https://www.instagram.com/bello_arte_flores/"
            target="_blank"
          >
            <InstagramIcon style={{ fontSize: 25 }} />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;

// Navbar.jsx
// import React from 'react';
// import { AppBar, Toolbar, Typography } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';

// function Navbar() {
//   return (
//     <AppBar position="static" color="transparent" elevation={0}>
//       <Toolbar className="justify-between">
//         <MenuIcon />
//         <Typography variant="h6">
//           Navbar
//         </Typography>
//       </Toolbar>
//     </AppBar>
//   );
// }

// export default Navbar;
