import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Box, Typography, IconButton, Button, TextField, Grid, Tooltip, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller } from 'react-hook-form';
import { Margin, ShoppingBasket as ShoppingCartIcon, Info as InfoIcon } from '@mui/icons-material';
import searchTerms from "../Data/sector.json";
import PopupMessage from '../ComponentsCustom/PopuMessage';

const Modalpedido = ({ isOpen, onRequestClose, image, name, price }) => {
    const { handleSubmit, control, formState: { errors }, setValue, getValues } = useForm();
    const [searchTerm, setSearchTerm] = useState("");
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedSector, setSelectedSector] = useState(null);
    const [total, setTotal] = useState(price);

    const onSubmit = (data) => {
        console.log(data); // Aquí puedes enviar los datos del formulario
        const whatsappMessage = `
✅ *Pedido Realizado*
————————————
 *Producto:* ${name}
 *Total:* $${total}
————————————
👉 *Recibe:* ${data.receiverName}
 *Teléfono:* ${data.phoneNumber}
 *Envía:* ${data.senderName}
————————————
📍 *Dirección de Entrega:* ${data.direccion}
 *Sector:* ${selectedSector ? selectedSector.name : 'No especificado'}
————————————
 *Mensaje de la Tarjeta:* ${data.smstarjet}
        `;

        const phoneNumber = '593990677029'; // Reemplaza con el número de teléfono de WhatsApp
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappLink, '_blank');
    };

    const handleSearchChange = (event, newValue) => {
        setSearchTerm(newValue ? newValue.label : "");
        setSelectedSector(newValue);
        setValue("sector", newValue); // Actualiza el valor del campo 'sector'
    };

    useEffect(() => {
        if (!selectedSector) {
            setTotal(parseFloat(price).toFixed(2)); // Si no hay sector seleccionado, solo devuelve el precio del producto
        } else {
            const calculatedTotal = parseFloat(price) + parseFloat(selectedSector.price);
            setTotal(calculatedTotal.toFixed(2)); // Redondea a 2 decimales
        }
    }, [price, selectedSector]);

    // Estilos dinámicos para el modal
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
        content: {
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '600px',
            width: '90%',
            height: '60%', // Altura por defecto para pantallas pequeñas
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#000',
        },
    };

    // Media query para ajustar el tamaño del modal en pantallas grandes
    if (window.innerWidth < 599) {
        customStyles.content.height = '92%';
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Detalles de la imagen"
            ariaHideApp={false}
            style={customStyles}
        >
            <Box className="flex justify-end w-full">
                <IconButton onClick={onRequestClose} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            {image && (
                <Box width="100%" p={2}>
                    <Grid container spacing={2}>
                        <div className="flex lg:flex-col xspeque:flex-row justify-center items-center m-auto">
                            <img src={image} alt={name} className="lg:w-60 lg:h-60 xspeque:w-100px xspeque:h-100px cursor-pointer" />
                            <div className="lg:ml-0 lg:mt-4 xspeque:ml-4 xspeque:mt-0">
                                <h6 className="text-center lg:text-left">
                                    {name}
                                </h6>
                                <h6 className="text-center lg:text-left">
                                    Precio: {price}
                                </h6>
                                <h2 className="text-center lg:text-left font-bold text-green-700 ">
                                    Total: ${total}
                                </h2>
                            </div>
                        </div>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                                    <Box width="100%">
                                        <Controller
                                            name="senderName"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Remitente (Envía)"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={!!errors.senderName}
                                                    helperText={errors.senderName ? 'Remitente requerido' : ''}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => setPopupOpen(true)}>
                                                                    <InfoIcon color="action" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Box mt={1} width="100%">
                                        <Controller
                                            name="receiverName"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Destinatario (Recibe)"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={!!errors.receiverName}
                                                    helperText={errors.receiverName ? 'Destinatario requerido' : ''}
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Box mt={1} width="100%">
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Número de teléfono"
                                                    variant="outlined"
                                                    fullWidth
                                                    type='number'
                                                    error={!!errors.phoneNumber}
                                                    helperText={errors.phoneNumber ? 'Número requerido' : ''}
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Box mt={1} width="100%">
                                        <Controller
                                            name="smstarjet"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Mensaje de la tarjeta"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline  // Habilita la entrada multilinea
                                                    rows={2}   // Número de filas que deseas mostrar inicialmente
                                                    error={!!errors.smstarjet}
                                                    helperText={errors.smstarjet ? 'Mensaje requerido' : ''}
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Box mt={1} width="100%">
                                        <Controller
                                            name="direccion"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Dirección"
                                                    variant="outlined"
                                                    fullWidth
                                                    error={!!errors.direccion}
                                                    helperText={errors.direccion ? 'Dirección requerida' : ''}
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </Box>
                                    <Controller
                                        name="sector"
                                        control={control}
                                        defaultValue={null}
                                        rules={{ required: 'Sector requerido' }}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                options={searchTerms}
                                                getOptionLabel={(option) => option.name + " - " + option.price + "$"}
                                                value={field.value}
                                                onChange={(event, newValue) => {
                                                    field.onChange(newValue);
                                                    handleSearchChange(event, newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Sector"
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{ marginTop: '10px' }}
                                                        error={!!errors.sector}
                                                        helperText={errors.sector ? 'Sector requerido' : ''}
                                                    />
                                                )}
                                            />
                                        )}
                                    />

                                    <Box mt={2} width="100%">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            startIcon={<ShoppingCartIcon />}
                                        >
                                            Pedido Total: ${total}
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {/* PopupMessage para mostrar el mensaje de ayuda */}
            <PopupMessage isOpen={popupOpen} onClose={() => setPopupOpen(false)} />
        </ReactModal>
    );
};

export default Modalpedido;
