import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import searchTerms from "../Data/categorias.json"; // Asegúrate de que la ruta es correcta
import PreciosEnvio from "../../Pages/PreciosDeEnvio/preciosenvio";
import Contactos from "../../Pages/Contactos/contactos";
import Inicio from "../../Pages/Inicio/Inicio";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            className="w-full bg-fondo_pastel_morado"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: 0, margin: 0, background: "#b8bedd" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function CatalogueTab() {
    const [value, setValue] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchTerm("");
    };

    const handleSearchChange = (event, newValue) => {
        setSearchTerm(newValue ? newValue.label : "");
    };

    return (
        <Box className="sticky mt-12 bg-fondo_pastel_morado " sx={{ width: "100%", position: "sticky" }}>
            <div className="sticky top-0 bg-fondo_pastel_morado z-10">
                <Tabs
                    value={value}
                    centered
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        position: "sticky",
                        flexDirection: { xs: 'column', sm: 'row' },
                        padding: "0px",
                        margin: "0px",
                    }}
                >
                    <Tab
                        label={<Typography 
                            // sx={{ fontWeight: 'bold' ,}}
                        >Regalos florales</Typography>}
                        {...a11yProps(0)}
                    />
                    {/* <Tab
                        label={<Typography 
                            // sx={{ fontWeight: 'bold' }}
                            >Precios Envío</Typography>}
                        {...a11yProps(1)}
                    /> */}
                    <Tab
                        label={<Typography 
                            // sx={{ fontWeight: 'bold' }}
                            >Contactos</Typography>}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                <Inicio />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                <PreciosEnvio />
            </TabPanel> */}
            <TabPanel value={value} index={1}>
                <Contactos />
            </TabPanel>
        </Box>
    );
}
